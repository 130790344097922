// import { Injectable } from '@angular/core';
// import { docData } from '@angular/fire/firestore';
// import { Router } from '@angular/router';
// import { Auth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, UserCredential } from 'firebase/auth';
// import { doc, Firestore, setDoc } from 'firebase/firestore';
// import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';
// import { KEY_USER } from 'src/environments/environment';
// import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, UserCredential, signInWithEmailAndPassword, onAuthStateChanged, signOut } from '@angular/fire/auth';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserData = null;

  private logincheck = new BehaviorSubject(0);

  logout$: Subject<boolean> = new Subject<boolean>();


  public userId = null;

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private router: Router,
    private storageService: StorageService,

  ) {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        const userDoc = doc(this.firestore, `users/${user.uid}`);
        docData(userDoc, { idField: 'id' }).pipe(
          takeUntil(this.logout$)
        ).subscribe(data => {
          this.currentUserData = data;
          // this.userId = user.uid;
          // TODO: storage
          this.storageService.set(environment.storage.USER, user.uid);
          this.loginEvent();

        })
      } else {
        this.currentUserData = null;
        // this.userId = null;
        // TODO: storage
        this.storageService.remove(environment.storage.USER);
        this.logoutEvent();
      }
    })
  }
  loginEvent() {
    const newlog = 1;
    this.logincheck.next(newlog);
  }

  logoutEvent() {
    const newlog = 0;
    this.logincheck.next(newlog);
  }

  getCount(): number {
    return this.logincheck.getValue();
  }

  getLogin(): Observable<number> {
    return this.logincheck.asObservable();
  }
  login({ email, password }) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async signup({ email, password }): Promise<UserCredential> {
    try {
      const credentials = await createUserWithEmailAndPassword(this.auth, email, password);
      const userDoc = doc(this.firestore, `users/${credentials.user.uid}`);
      await setDoc(userDoc, { email, chats: [] });
      return credentials;
    } catch (err) {
      throw (err);
    }
  }

  async logout() {
    await signOut(this.auth);
    this.logout$.next(true);
    // this.userId = null;
    // TODO: storage
    this.storageService.remove(environment.storage.USER);
    this.logoutEvent();

    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
