import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastmessageService {


  constructor(
    private tms: ToastController
  ) { }

  async loginTM() {
    let toast = await this.tms.create({
      message: 'Login Erfolgreich',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async loginTM_not() {
    let toast = await this.tms.create({
      message: 'Login NICHT Erfolgreich',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }

  async memberupdate() {
    let toast = await this.tms.create({
      message: 'Vorstand erfolgreich gespeichert',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async memberupdateNot() {
    let toast = await this.tms.create({
      message: 'Vorstand NICHT erfolgreich gespeichert',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }

  async memberadd() {
    let toast = await this.tms.create({
      message: 'Vorstand erfolgreich hinzugefügt',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async memberaddNot() {
    let toast = await this.tms.create({
      message: 'Vorstand NICHT erfolgreich hinzugefügt',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }

  async memberdelete() {
    let toast = await this.tms.create({
      message: 'Vorstand erfolgreich gelöscht',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async memberdeleteNot() {
    let toast = await this.tms.create({
      message: 'Vorstand NICHT erfolgreich gelöscht',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async clubdeleteNot() {
    let toast = await this.tms.create({
      message: 'Clubprogram NICHT gelöscht',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }

  async clubdelete() {
    let toast = await this.tms.create({
      message: 'Clubprogram Erfolgreich gelöscht',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async clubadd() {
    let toast = await this.tms.create({
      message: 'Clubprogram Erfolgreich gespeichert',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async clubaddNot() {
    let toast = await this.tms.create({
      message: 'Clubprogram Nicht gespeichert',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }
  async clubUpdate() {
    let toast = await this.tms.create({
      message: 'Clubprogram Erfolgreich Bearbeitet',
      duration: 3000,
      position: 'middle',
      color: 'success'
    });
    await toast.present();
  }

  async clubUpdateNot() {
    let toast = await this.tms.create({
      message: 'Clubprogram Nicht Bearbeitet',
      duration: 3000,
      position: 'middle',
      color: 'danger'
    });
    await toast.present();
  }
}