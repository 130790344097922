import { Injectable } from '@angular/core';
import { addDoc, collection, collectionData, deleteDoc, doc, docData, Firestore, updateDoc } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Member } from '../interfaces/member';


@Injectable({
  providedIn: 'root'
})

export class MemberService {

  constructor(
    private firestore: Firestore
  ) { }

  getAllTeammembers(): Observable<Member[]> {
    const membersRef = collection(this.firestore, 'members');
    return collectionData(membersRef, { idField: 'id' }) as Observable<Member[]>;
  }

  getTeamMemberById(id): Observable<Member> {
    const memberDocRef = doc(this.firestore, `members/${id}`);
    return docData(memberDocRef, { idField: 'id' }) as Observable<Member>;
  }

  addTeamMember(member: Member) {
    const membersRef = collection(this.firestore, 'members');
    return addDoc(membersRef, member);
  }

  deleteTeamMember(member: Member): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const memberDocRef = doc(this.firestore, `members/${member.id}`);

        let x = deleteDoc(memberDocRef)
        return resolve(x);
      } catch (e) {
        return e;
      }
    });
  }

  updateTeamMember(member: Member) {
    const memberDocRef = doc(this.firestore, `members/${member.id}`);
    return updateDoc(memberDocRef, { id: member.id, image: member.image, fullname: member.fullname, title: member.title, email: member.email, phone: member.phone, order: member.order });
  }

}
