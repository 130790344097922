// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// motorclub-hp
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyCRv4yKcbrbf_NufG9Z_FQ1g47o05hTV54",
//     authDomain: "motorclub-hp.firebaseapp.com",
//     projectId: "motorclub-hp",
//     storageBucket: "motorclub-hp.appspot.com",
//     messagingSenderId: "508049265070",
//     appId: "1:508049265070:web:9237b17263f2f841cb383f",
//     measurementId: "G-J81KZ18F9Q"
//   }
// };

// mrcwattens2022
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDcyY9PGsjsrcZJQU0EjaZj20H2O24jIxU",
    authDomain: "mrcwattens-2022.firebaseapp.com",
    databaseURL: "https://mrcwattens-2022-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mrcwattens-2022",
    storageBucket: "mrcwattens-2022.appspot.com",
    messagingSenderId: "688384748485",
    appId: "1:688384748485:web:fecda485c67ba20f0df9ac",
    measurementId: "G-51ZCX2KEBW"
  },
  storage: {
    IMAGES: "IMAGES",
    USER: "USER",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
