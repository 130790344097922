import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this.storage.set(key, JSON.stringify(value)).then()
  }

  public async get(value: any):Promise<any> {
    // return JSON.parse(await this.storage.get(value));

    // todo resolve reject
    return new Promise(async (resolve, reject) => {
      return resolve(JSON.parse(await this.storage.get(value)));
    })
  }
  public async clear() {
    await this.storage.clear()
  }

  public async remove(key: string) {
    await this.storage.remove(key);
  }
}