import { Injectable } from '@angular/core';
import { collection, collectionData, doc, Firestore, updateDoc } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
//import { Bg } from '../interfaces/bg';


// TODO: https://angular.io/errors/NG0201
@Injectable({ providedIn:'root'})

export class FbBGService {
  
  constructor(
    private firestore: Firestore,
  ) { }

  getAllBgConfig(): Observable<any[]> {
    const gallerysRef = collection(this.firestore, 'bgimg');
    return collectionData(gallerysRef, { idField: 'id' }) as Observable<any[]>;
  }

  updateBgConfig(bgimg: any) {
    const BgDocRef = doc(this.firestore, `bgimg/${bgimg.id}`);
    return updateDoc(BgDocRef, { imgUrl: bgimg.imgUrl });
  }
  // getAllBgConfig(): Observable<any[]> {
  //   const gallerysRef = collection(this.firestore, 'bgimg');
  //   return collectionData(gallerysRef, { idField: 'id' }) as Observable<any[]>;
  // }


}
