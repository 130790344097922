import { Component } from '@angular/core';
import { user } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBarPlugin } from '@capacitor/status-bar';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Member } from './interfaces/member';
import { LoginPage } from './pages/login/login.page';
import { AuthService } from './services/auth.service';
import { FbBGService } from './services/fbbg.service';
import { MemberService } from './services/member.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/folder/Home', icon: 'home' },
    { title: 'Über uns', url: '/folder/About', icon: 'body' },
    { title: 'Vorstand', url: '/folder/Vorstand', icon: 'people' },
    { title: 'Clubprogramm', url: '/folder/Clubprogramm', icon: 'attach' },
    { title: 'Galerie', url: '/folder/Galerie', icon: 'albums' },
    { title: 'Kontakt', url: '/folder/Kontakt', icon: 'people-circle' },
  ];
  authiUser: boolean = false;
  membersSt: Member[];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    private router: Router,
    private platform: Platform,
    private statusBar: StatusBar,
    private activatedRoute: ActivatedRoute,
    private fbbg: FbBGService,
    private memberService: MemberService,
    private dialog: MatDialog,
    private authService: AuthService,
    private storageService: StorageService,
    private menu: MenuController
  ) { }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#ffffff');
      this.router.navigateByUrl('splash');
    })
  }

  async ngOnInit() {
    this.memberService.getAllTeammembers().subscribe(x => {
      this.membersSt = x;
      this.storageService.set("MEMB_KEY", x);
    });

    this.fbbg.getAllBgConfig().subscribe(x => {
      this.storageService.set("BG_KEY", x);
      console.log('storage set BG Image ');
    });

    // TODO: member von Storage holen
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    let storU = await this.storageService.get(environment.storage.USER);
    if (storU)
      this.authiUser = true;
    else
      this.authiUser = false;
  }

  login() {
    this.menu.close();
    const dialogRef = this.dialog.open(LoginPage, {
      panelClass: 'myDialog',
      // TODO:Richtigen User mitschicken
      data: user,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: ', result);
      // UPDATE
      // TODO: bessere lösung finden
      window.location.reload();
      if (result) {
        let member = result;
        if (member) {
        }
      }
    });
  }
  logout() {
    this.authService.logout();
    window.location.reload();
  }
}
