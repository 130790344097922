import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { User } from 'firebase/auth';
import { FolderPage } from 'src/app/folder/folder.page';
import { Member } from 'src/app/interfaces/member';
import { StorageService } from 'src/app/services/storage.service';
import { ToastmessageService } from 'src/app/services/toastmessage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  @Injectable()
  credentialsForm: FormGroup;
  log: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private tms: ToastmessageService,
    private authService: AuthService,
    private storageService:StorageService,
    public dialogRef: MatDialogRef<FolderPage>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) { }

  async ngOnInit() {
    this.credentialsForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(6), Validators.required]]
    });
    let storU = await this.storageService.get(environment.storage.USER);
    if (storU)
      this.log = true;
    else
      this.log = false;
  }

  // async register() {
  //   const loading = await this.loadingCtrl.create();
  //   await loading.present();

  //   this.authService.signup(this.credentialsForm.value).then(_ => {
  //     loading.dismiss();
  //     this.router.navigateByUrl('/', { replaceUrl: true });
  //     this.dialogRef.close(this.log);
  //   }, async err => {
  //     await loading.dismiss();

  //     const alert = await this.alertCtrl.create({
  //       header: 'Login fehlgeschlagen',
  //       message: 'Bitte versuche es später nocheinmal. Grund: ' + err,
  //       buttons: ['OK']
  //     });
  //     await alert.present();
  //   });
  // }

  async login() {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    this.authService.login(this.credentialsForm.value).then(user => {
      console.log(user.user.email);
      this.tms.loginTM()
      // this.tms.loginToastMessage();
      loading.dismiss();
      this.dialogRef.close(this.log);
      //this.router.navigateByUrl('/inside', { replaceUrl: true });
      this.router.navigateByUrl('', { replaceUrl: true });
    }, async err => {
      await loading.dismiss();
      this.tms.loginTM_not();
      const alert = await this.alertCtrl.create({
        header: 'Login Fehler',
        message: err.message,
        buttons: ['OK']
      });
      await alert.present();
    });
  }

  close() {
    this.dialogRef.close();
  }

}