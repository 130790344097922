import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


// TODO: Quelle Ionic Akademy:  https://www.youtube.com/watch?v=-c0htV-kfm8
const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'splash',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    redirectTo: 'folder/Home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'sub-gallery',
    loadChildren: () => import('./pages/sub-gallery/sub-gallery.module').then( m => m.SubGalleryPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'detailmember',
    loadChildren: () => import('./pages/detailmember/detailmember.module').then( m => m.DetailmemberPageModule)
  },
  {
    path: 'add-member',
    loadChildren: () => import('./pages/add-member/add-member.module').then( m => m.AddMemberPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },  {
    path: 'add-clubprogram',
    loadChildren: () => import('./pages/add-clubprogram/add-clubprogram.module').then( m => m.AddClubprogramPageModule)
  },
  {
    path: 'image-open',
    loadChildren: () => import('./pages/image-open/image-open.module').then( m => m.ImageOpenPageModule)
  },
  {
    path: 'image-open-show',
    loadChildren: () => import('./pages/image-open-show/image-open-show.module').then( m => m.ImageOpenShowPageModule)
  },
  {
    path: 'video-open-show',
    loadChildren: () => import('./pages/video-open-show/video-open-show.module').then( m => m.VideoOpenShowPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
